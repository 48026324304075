import React, { useState, useEffect, useCallback } from 'react'
import { Box, Button, TextField } from '@mui/material/';
import { useLocation } from "react-router-dom"
import { GoogleMap, LoadScript, MarkerF, Circle, Polyline, InfoWindow } from "@react-google-maps/api"
import useSWR from "swr"

const Map = () => {
    const search = useLocation().search
    const query = new URLSearchParams(search)
    const latG = query.get('lat')
    const lngG = query.get('lng')
    const NNumber = query.get('n')
    const DB = query.get('db')
    const AlertID = query.get('id')
    //フェリーリスト取得
    const { data: ferryList, mutate, error } = useSWR("https://bahati.work/api/map/API_Ferry_R.php", fetcher)
    const { data: dailyList } = useSWR(DB ? { url: 'https://bahati.work/api/map/API_Daily_R.php', args: { db: DB, n: NNumber, id: AlertID } } : null, fetcher2)
    const { data: line } = useSWR(DB ? { url: 'https://bahati.work/api/map/API_Line_R.php', args: { db: DB, n: NNumber } } : null, fetcher2)

    console.log(line)


    const [center, setCenter] = useState({
        lat: 34.9475505,
        lng: 138.3967816,
    })

    //クリックした場所用
    const [addPoint, setAddPoint] = useState(null)
    //アラート発生場所用
    //const [alertPoint, setAlertPoint] = useState(null)

    //港名
    const [harborAdd, setHarborAdd] = useState("")
    const harborSet = (e) => {
        setHarborAdd(e.target.value)
    }
    //ターミナル名
    const [terminalAdd, setTerminalAdd] = useState("")
    const terminalSet = (e) => {
        setTerminalAdd(e.target.value)
    }
    //フェリー追加ボタンdisabledチェック
    const [ferryAdd, setFerryAdd] = useState(true)

    const containerStyle = {
        height: "80vh",
        width: "100%",
    }

    const options = {
        //disableDefaultUI: true,
        zoomControl: true,
        force: 'lite',
    }
    const Lineoptions = {
        strokeColor: '#33FF33',
        strokeOpacity: 0.5,
        strokeWeight: 2,
        fillColor: '#33FF33',
        fillOpacity: 0.5,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        zIndex: 1,
    }
    const circleOptions = {
        strokeColor: "#11FF11",
        strokeOpacity: 0.5,
        strokeWeight: 2,
        fillColor: "#11FF11",
        fillOpacity: 0.35,
        visible: true,
        zIndex: 1,
    }
    const infoWindowOptions = {
        disableAutoPan: true,
    }
    const infoWindowAlertStyle = {
        color: "#FF6666",
    }

    const mapClicked = (event) => {
        setAddPoint({ lat: event.latLng.lat(), lng: event.latLng.lng() })
    }

    const clearPoint = () => {
        setAddPoint(null)
        setHarborAdd("")
        setTerminalAdd("")
    }

    async function fetcher(key) {
        return fetch(key, {
            method: 'POST',
            cache: 'no-cache',
            mode: 'cors',
        }).then((res) => res.json())
    }
    async function fetcher2(key) {
        return fetch(key.url, {
            method: 'POST',
            cache: 'no-cache',
            mode: 'cors',
            body: JSON.stringify(key.args),
        }).then((res) => res.json())
    }

    const addFerry = useCallback(async () => {
        await mutate(async () => {
            const jsonData = {
                lat: addPoint.lat,
                lng: addPoint.lng,
                harbor: harborAdd,
                terminal: terminalAdd,
            }
            const url = "https://bahati.work/api/map/API_Ferry_Add.php"
            const res = await fetch(url, {
                method: 'POST',
                cache: 'no-cache',
                mode: 'cors',
                body: JSON.stringify(jsonData)
            })
            const data = await res.json()
            return data
        })
        setAddPoint(null)
        setHarborAdd("")
        setTerminalAdd("")
    }, [mutate, addPoint, harborAdd, terminalAdd])

    useEffect(() => {
        if (latG !== null && lngG !== null) {
            setCenter({ lat: parseFloat(latG), lng: parseFloat(lngG) })
            //setAlertPoint({ lat: parseFloat(latG), lng: parseFloat(lngG) })
        } else {
            if (line && line.length) {
                setCenter({ lat: line[0].lat, lng: line[0].lng })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [latG, lngG, line])


    //新規追加ボタンdisabledチェック
    useEffect(() => {
        if ((terminalAdd !== "" || harborAdd !== "") && addPoint !== null) {
            setFerryAdd(false)
        } else {
            setFerryAdd(true)
        }
    }, [terminalAdd, harborAdd, addPoint])

    return (
        <>
            {ferryList === undefined ? (
                <div>Loading...</div>
            ) : (
                <LoadScript googleMapsApiKey={process.env.REACT_APP_MAP_API_KEY}>
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={16}
                        options={options}
                        onClick={mapClicked}
                    >
                        {ferryList.map((d, i) => (
                            <>
                                <MarkerF
                                    key={'marker' + i}
                                    position={{ lat: parseFloat(d.lat), lng: parseFloat(d.lon) }}
                                    label={{
                                        text: d.harbor + ' ' + d.terminal,
                                        color: '#333',
                                        fontSize: '16px',
                                    }}
                                    onClick={() => alert(d.harbor + ' ' + d.terminal)}
                                />
                                <Circle center={{ lat: parseFloat(d.lat), lng: parseFloat(d.lon) }} radius={500} options={circleOptions} />
                            </>
                        ))}

                        {addPoint && (
                            <>
                                <MarkerF
                                    key={'addPoint'}
                                    position={{ lat: parseFloat(addPoint.lat), lng: parseFloat(addPoint.lng) }}
                                    label={{
                                        text: 'フェリー追加',
                                        color: '#333333',
                                        fontSize: '16px',
                                    }}
                                    icon={{
                                        url: "https://maps.google.com/mapfiles/ms/micons/green-dot.png",
                                        labelOrigin: new window.google.maps.Point(20, 50),
                                    }}
                                />
                                <Circle key={'circle'} center={{ lat: parseFloat(addPoint.lat), lng: parseFloat(addPoint.lng) }} radius={500} options={circleOptions} />
                            </>
                        )}

                        {dailyList && (
                            <>
                                {dailyList.map((d, i) => (
                                    <>
                                        {/* 
                                        <MarkerF
                                            key={'marker' + i}
                                            position={{ lat: parseFloat(d.lat), lng: parseFloat(d.lng) }}
                                            label={{
                                                text: d.no + "@" + d.w_type,
                                                color: '#333',
                                                fontSize: '16px',
                                            }}
                                            icon={"https://maps.google.com/mapfiles/ms/micons/green-dot.png"}
                                        />
                                        */}
                                        <InfoWindow
                                            position={{ lat: parseFloat(d.lat), lng: parseFloat(d.lng) }}
                                            options={infoWindowOptions}
                                            key={'info' + i}
                                        >
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>No.</td>
                                                        <td>{d.no}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>作業</td>
                                                        <td>{d.w_type}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>開始日時</td>
                                                        <td>{d.s_datetime}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>終了日時</td>
                                                        <td>{d.f_datetime}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>作業時間</td>
                                                        <td>{d.work_time}</td>
                                                    </tr>
                                                    {d.alertType && (
                                                        <tr>
                                                            <td>種別</td>
                                                            <td style={infoWindowAlertStyle}>{d.alertType}</td>
                                                        </tr>
                                                    )}
                                                    {d.phenomenon && (
                                                        <tr>
                                                            <td>事象</td>
                                                            <td style={infoWindowAlertStyle}>{d.phenomenon}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </InfoWindow>
                                    </>
                                ))}
                            </>
                        )}

                        {line && (
                            <Polyline
                                path={line}
                                options={Lineoptions}
                            />
                        )}

                    </GoogleMap>
                </LoadScript>
            )}
            <Box sx={{ width: '100%' }}>
                <TextField
                    sx={{ margin: '10px' }}
                    label="港"
                    variant="outlined"
                    onChange={harborSet}
                    value={harborAdd}
                />
                <TextField
                    sx={{ margin: '10px' }}
                    label="ターミナル"
                    variant="outlined"
                    onChange={terminalSet}
                    value={terminalAdd}
                />
                <Button
                    sx={{ margin: '10px', padding: '14px' }}
                    size={'large'}
                    variant="contained"
                    onClick={() => addFerry()}
                    disabled={ferryAdd}
                >
                    フェリー乗場追加
                </Button>
                <Button
                    sx={{ margin: '10px', padding: '14px' }}
                    size={'large'}
                    variant="outlined"
                    onClick={() => clearPoint()}
                >
                    Clear
                </Button>
            </Box>

            {error ? <p>{error}</p> : null}
        </>
    )
}
export default Map;